let aboutHero = document.querySelector(".person-scroll-widget");
let aboutHeroImages;
let aboutHeroImageArray;
let aboutHeroGap;
let zIndex = 2;
let currentStep = 0;
let interval;

function updateAboutHero(progress) {
    const step = Math.floor(((progress) ? progress : 0) / aboutHeroGap);
    if(step !== currentStep) showStep(step);
}

function showStep(index) {
    currentStep = index;
    if (aboutHeroImageArray[index]) {
        aboutHeroImageArray[index].style.zIndex = zIndex;
    }
    zIndex++;
}

window.addEventListener("load", function() {
    if (!aboutHero) {
        return;
    }

    aboutHeroImages = aboutHero.getElementsByTagName("img");
    aboutHeroImageArray = Array.from(aboutHeroImages);
    aboutHeroGap = 1 / aboutHeroImageArray.length;

    if (document.body.clientWidth < 991) {
        interval = setInterval(() => {
            showStep((currentStep + 1) % aboutHeroImageArray.length)
        }, 1000);
    } else {
        let firstWidgetImage = document.querySelector(".person-scroll-widget__image");
        if (firstWidgetImage) {
            firstWidgetImage.style.zIndex = "1";
        }

        updateAboutHero(0);

        gsap.registerPlugin(ScrollTrigger);

        let scrollTrigger = ScrollTrigger.create({
            trigger: '.person-scroll-widget__content',
            start: '400px bottom',
            end: 'bottom 500px',
            scrub: 1,
            // markers: true,
            onUpdate: function() {
                updateAboutHero((scrollTrigger) ? scrollTrigger.progress : 0);
            }
        })
    }
});
